import React, { createContext, useState } from "react"

export const ModalContext = createContext({})

const ModalProvider = ({ children }) => {
  const [modalVisibility, setModalVisibility] = useState(false)
  const [closeBtnVisibility, setCloseBtnVisibility] = useState(true)
  const [modalContent, setModalContent] = useState(null)
  const [modalKeyDownEvent, setModalKeyDownEvent] = useState(null)

  return (
    <ModalContext.Provider
      value={{
        modalVisibility,
        setModalVisibility,
        closeBtnVisibility,
        setCloseBtnVisibility,
        modalContent,
        setModalContent,
        modalKeyDownEvent,
        setModalKeyDownEvent,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
