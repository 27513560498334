import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Navigation from "./Navigation"
import QuickAccessMenu from "./Menus/QuickAccessMenu"

const SiteTitle = () => (
  <>
    <span className="block text-xl lg:text-6xl font-semibold font-important uppercase">
      Coucy
    </span>
    <span className="font-handwritten lg:text-4xl font-normal">
      Vert l'avenir
    </span>
  </>
)

const Header = ({ isHome }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const [navbarVisibility, setNavbarVisibility] = useState(false)

  return (
    <>
      <div
        className={`header-background relative h-72 ${
          isHome ? ` md:h-96 xl:h-120 2xl:h-152` : ``
        } z-50`}
      >
        <div className="absolute inset-0">
          <img
            className="h-full w-full object-cover"
            src="/background.jpg"
            alt="Bienvenue sur notre site"
          />
        </div>

        <div className="relative flex z-50 lg:z-10">
          <div
            className={`lg:absolute left-0 top-0 px-4 lg:px-12 py-4 ${
              isHome ? `lg:py-12` : `lg:py-8`
            }`}
          >
            {isHome ? (
              <h1 className="site-logo text-white text-center leading-none text-base">
                <SiteTitle />
              </h1>
            ) : (
              <Link
                className="site-logo group block leading-none text-white text-center hover:text-white"
                to="/"
                title={data.site.siteMetadata.title}
              >
                <SiteTitle />
              </Link>
            )}
          </div>
          <div
            className={`lg:absolute flex-1 right-0 top-0 lg:transform-none text-center px-4 lg:px-12 py-4 ${
              isHome ? `lg:py-12` : `lg:py-8`
            }`}
          >
            <QuickAccessMenu
              visibility={navbarVisibility}
              visibilityHandler={setNavbarVisibility}
            />
          </div>
        </div>

        <div
          className={`${
            navbarVisibility ? `block` : `hidden`
          } lg:block absolute inset-x-0 top-0 lg:top-auto lg:bottom-0 lg:transform lg:translate-y-1/2 z-40`}
        >
          <div className="lg:container lg:relative lg:mx-auto lg:px-4 pt-20 pb-10 lg:pt-0 lg:pb-0 bg-primary lg:bg-transparent">
            <Navigation id={`main-navigation`} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
