import React from "react"
import { connectAutoComplete } from "react-instantsearch-dom"

const Autocomplete = React.forwardRef(
  ({ hits, currentRefinement, refine, inputRef }) => (
    <div className="search-autocomplete relative">
      <label htmlFor="search-autocomplete-input" className="hidden">
        Recherche
      </label>
      <div
        className={`icon absolute right-0 inset-y-0 py-2 px-4 text-primary ${
          currentRefinement.length > 0 ? `hidden` : `block`
        }`}
      >
        <i className="fas fa-search" style={{ lineHeight: "inherit" }}>
          <span className="hidden">Rechercher</span>
        </i>
      </div>
      <input
        ref={inputRef}
        id="search-autocomplete-input"
        name="search-autocomplete-input"
        type="search"
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
        className="search w-full py-2 px-4 border focus:border-primary focus:outline-none"
        placeholder="Tapez ici votre recherche…"
        autoComplete="off"
      />
    </div>
  )
)

export default connectAutoComplete(Autocomplete)
