import React, { useContext, useEffect, useRef } from "react"
import { ModalContext } from "../../contexts/ModalContext"

const Modal = () => {
  const {
    modalVisibility,
    setModalVisibility,
    closeBtnVisibility,
    modalContent,
    setModalContent,
    setModalKeyDownEvent,
    modalKeyDownEvent,
  } = useContext(ModalContext)

  const modalRef = useRef(null)

  useEffect(() => {
    let body = document.body

    if (modalVisibility) {
      modalRef.current.focus()
      body.classList.add("overflow-hidden")
    } else {
      body.classList.remove("overflow-hidden")
      setModalContent(null)
    }
  }, [modalVisibility, setModalContent])

  useEffect(() => {
    if (null !== modalKeyDownEvent) {
      switch (modalKeyDownEvent.key) {
        case "Escape":
          setModalVisibility(false)
          break
        default:
          break
      }
    }
  }, [modalKeyDownEvent, setModalVisibility])

  return (
    <div
      ref={modalRef}
      className={`modal-overlay fixed inset-0 flex flex-col items-center lg:justify-center py-4 lg:py-12 bg-black bg-opacity-75 z-100 overflow-auto ${
        modalVisibility ? `block` : `hidden`
      }`}
      onKeyDown={setModalKeyDownEvent}
      onClick={() => setModalVisibility(false)}
      tabIndex={0}
      role="button"
    >
      <div
        className="container mx-auto px-4 lg:max-w-screen-lg max-h-full"
        onClick={event => event.stopPropagation()}
        role="presentation"
      >
        <div className="relative flex flex-col items-center justify-center h-full">
          {modalContent}
        </div>
      </div>
      {closeBtnVisibility && (
        <div
          className="close absolute top-0 right-0 flex items-center justify-center h-12 w-12 m-8 lg:m-6 bg-primary text-white text-2xl rounded-full cursor-pointer"
          onClick={() => setModalVisibility(false)}
          onKeyPress={event =>
            event.key === "Enter" && setModalVisibility(false)
          }
          role="button"
          tabIndex={0}
        >
          <i className="fas fa-times">
            <span className="hidden">Fermer</span>
          </i>
        </div>
      )}
    </div>
  )
}

export default Modal
