import React from "react"
import { connectHighlight } from "react-instantsearch-dom"

const Highlight = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: "_highlightResult",
    attribute,
    hit,
  })

  return (
    <span>
      {parsedHit.map((part, index) => (
        <span key={index} className={part.isHighlighted ? `font-semibold` : ``}>
          {part.value}
        </span>
      ))}
    </span>
  )
}

export default connectHighlight(Highlight)
