import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const LogoVVF = ({ ...props }) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logo-vvf.png" }) {
        childImageSharp {
          fluid(maxWidth: 256, maxHeight: 95, cropFocus: CENTER, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.file.childImageSharp.fluid} {...props} />
}

export default LogoVVF
