import React from "react"
import PropTypes from "prop-types"
import Header from "./Header"
import { Flash } from "./Flash"
import ModalProvider from "../contexts/ModalContext"
import Modal from "./Modal/Modal"
import Footer from "./Footer"
import FlashInfo from "./Blocks/FlashInfo"
import Scrolltop from "./Scrolltop"

import "../styles/index.scss"

const Layout = ({ children, isHome, contentType }) => {
  return (
    <>
      <ModalProvider>
        <Scrolltop>
          <FlashInfo />
          {contentType && (
            <div data-typesense-field="content_type" className="hidden">
              {contentType}
            </div>
          )}
          <Header isHome={isHome} />
          <main
            id="main-content"
            className="relative overflow-hidden z-40"
            role="main"
          >
            {isHome && (
              <div
                className="absolute top-0 right-0 bg-primary-light bg-opacity-10 transform origin-bottom-right"
                style={{
                  width: "200vw",
                  height: "80vw",
                  transform: "rotate(32deg)",
                }}
              >
                &nbsp;
              </div>
            )}
            <div id="content--region" className="region content--region">
              {children}
              <Flash />
            </div>
          </main>
          <Footer />
          <Modal />
        </Scrolltop>
      </ModalProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
  typesense: PropTypes.bool,
}

Layout.defaultProps = {
  isHome: false,
  typesense: false,
}

export default Layout
