import React from "react"
import { Link } from "gatsby"
import { connectHits } from "react-instantsearch-dom"
import CustomHighlight from "./CustomHighlight"
import CustomHitsContentType from "./CustomHitsContentType"

const Hits = ({ hits }) => {
  return (
    <>
      {hits.map(hit => (
        <Link
          to={hit.page_path}
          className="block p-2 text-important text-primary"
          key={hit.objectID}
        >
          <CustomHighlight hit={hit} attribute="title" />
          &nbsp;
          <CustomHitsContentType
            contentType={hit.content_type ? hit.content_type : "node__page"}
          />
        </Link>
      ))}
    </>
  )
}

export default connectHits(Hits)
