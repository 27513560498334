import React, { useState, createRef } from "react"
import { classNames } from "../Utils/functions"

const Scrolltop = ({ children }) => {
  const [visibility, setVisibility] = useState(false)
  const topRef = createRef()

  const scrollHandler = e => {
    if (e.target.scrollTop > e.target.clientHeight) {
      !visibility && setVisibility(true)
    } else {
      visibility && setVisibility(false)
    }
  }

  return (
    <div onScroll={scrollHandler} className="h-screen overflow-auto">
      <div ref={topRef}>
        <span className="sr-only">Haut de page</span>
      </div>
      {children}
      <div className="scrolltop inlint-block fixed bottom-4 right-4 z-50">
        <button
          className={classNames(
            visibility ? "opacity-1 scale-100" : "opacity-0 scale-75",
            "w-8 h-8 group bg-secondary text-white shadow transition-all"
          )}
          onClick={() =>
            topRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "center",
            })
          }
        >
          <i className="block fas fa-arrow-up transform group-hover:-translate-y-1 transition-all"></i>
          <span className="sr-only">Retour en haut de page</span>
        </button>
      </div>
    </div>
  )
}

export default Scrolltop
