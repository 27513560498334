import React from "react"
import SearchBar from "../SearchBar"

export const ModalSearch = () => (
  <div className="modal-search relative max-h-screen overflow-auto w-full border border-primary bg-white p-4 lg:p-8 xl:p-12">
    <h2 className="title h2 leading-none mb-6">
      <span className="block font-handwritten font-normal text-4xl text-gray-dark">
        Vivre à Coucy
      </span>
      <small className="text-primary uppercase">Rechercher</small>
    </h2>
    <SearchBar />
  </div>
)
