import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { drupalMenuSorter } from "../../Utils/functions"
import DrupalMenuRenderer from "../DrupalMenuRenderer"

const MainMenu = () => {
  const [openedIndex, setOpenedIndex] = useState(null)
  const [activeIndex, setActiveIndex] = useState(null)

  const clickHandler = (event, index, isOpenable) => {
    if (isOpenable) {
      event.preventDefault()
      setOpenedIndex(index === openedIndex ? null : index)
    } else {
      setOpenedIndex(null)
      setActiveIndex(index)
    }
  }

  const data = useStaticQuery(graphql`
    {
      mainMenu: allMenuLinkContentMenuLinkContent(
        filter: { enabled: { eq: true }, menu_name: { eq: "main" } }
        sort: { fields: [weight, title] }
      ) {
        edges {
          node {
            title
            drupal_parent_menu_item
            drupal_id
            weight
            link {
              uri
              options {
                attributes {
                  target
                  data_text_invisible
                  data_icon
                  data_has_icon
                  data_submenu_force_icon
                  class
                }
                container_attributes {
                  class
                }
              }
            }
          }
        }
      }
    }
  `)

  let menu = drupalMenuSorter(data.mainMenu.edges)

  return (
    <DrupalMenuRenderer
      menu={menu}
      maxLevel={1}
      activeIndex={activeIndex}
      openedIndex={openedIndex}
      clickHandler={clickHandler}
    />
  )
}

export default MainMenu
