import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import HtmlParser from "../HtmlParser"

const FlashInfo = () => {
  const data = useStaticQuery(graphql`
    {
      flashInfo: allNodeFlashInfo(
        filter: { status: { eq: true }, current: { eq: true } }
        sort: { fields: created, order: DESC }
        limit: 1
      ) {
        nodes {
          drupal_id
          title
          body {
            value
          }
          start: field_date_start
          end: field_date_end
        }
      }
    }
  `)

  const flash = data.flashInfo.nodes.length > 0 ? data.flashInfo.nodes[0] : null
  const start = flash ? new Date(flash.start) : null
  const end = flash ? new Date(flash.end) : null
  const now = new Date()

  return flash ? (
    <>
      {start <= now && now <= end && (
        <div id="flash-info">
          <div className="flex flex-col lg:flex-row">
            <div className="flash-title text-center">
              <div className="inline-flex lg:flex items-center py-2 pl-4 lg:pl-12 pr-4 bg-primary text-white">
                <div className="lg:px-2 mr-2 lg:mr-0">
                  <div className="icon flex items-center justify-center w-8 h-8 lg:w-14 lg:h-14 text-lg lg:text-4xl bg-secondary bg-opacity-20 rounded-full">
                    <i className="fas fa-bullhorn transform -rotate-12"></i>
                  </div>
                </div>
                <div className="lg:px-2 font-semibold text-lg uppercase">
                  <span className="inline-block lg:block">Flash&nbsp;</span>
                  <span className="inline-block bg-white text-primary px-2">
                    infos
                  </span>
                </div>
              </div>
            </div>
            <div className="flash-content flex items-center text-center lg:text-left">
              <div className="py-4 lg:py-2 pr-4 lg:pr-12 pl-4">
                <div className="content">
                  <HtmlParser html={flash.body.value} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  ) : (
    <></>
  )
}

export default FlashInfo
