import React from "react"
import InfosBlock from "./Blocks/InfosBlock"
import FooterMenu from "./Menus/FooterMenu"
import MapBlock from "./Blocks/MapBlock"
import OpeningHoursBlock from "./Blocks/OpeningHoursBlock"

const Footer = () => (
  <footer
    id="footer"
    className="footer py-8 lg:py-16 bg-primary text-white text-center md:text-left"
  >
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="column">
          <InfosBlock />
        </div>
        <div className="column lg:col-span-2">
          <MapBlock />
        </div>
        <div className="column md:col-span-2 lg:col-span-1">
          <OpeningHoursBlock />
        </div>
      </div>
    </div>
    <div className="md:text-center mt-8 lg:mt-12 border-t md:border-t-0 border-secondary">
      <div className="container relative mx-auto px-4">
        <FooterMenu />
        <div className="copyright text-sm md:text-center mt-2">
          Copyright ©Commune de Coucy -{" "}
          <a
            href="https://isics.fr"
            title="ISICS, expérience digitale"
            target="_blank"
            rel="noreferrer"
            className="hover:text-secondary"
          >
            Une création ISICS
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
