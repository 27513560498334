import React, { useEffect, useRef } from "react"
import { InstantSearch, connectStateResults } from "react-instantsearch-dom"
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter"
import CustomAutocomplete from "./Search/CustomAutocomplete"
import CustomHits from "./Search/CustomHits"

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: process.env.TYPESENSE_API_KEY,
    nodes: [
      {
        host: process.env.TYPESENSE_HOST,
        port: process.env.TYPESENSE_PORT,
        protocol: process.env.TYPESENSE_PROTOCOL,
      },
    ],
  },
  additionalSearchParameters: {
    queryBy: "title,body,paragraph_text,paragraph_highlighted",
  },
})

const searchClient = typesenseInstantsearchAdapter.searchClient

const Results = connectStateResults(
  ({ searchState, searchResults, children }) => {
    if (searchState && searchState.query) {
      if (searchResults && searchResults.nbHits !== 0) {
        return <div className="py-2 mt-8">{children}</div>
      } else {
        return (
          <div className="py-2 mt-8">
            <p className="p-2 text-important">
              Aucun résultat ne correspond à votre recherche.
            </p>
          </div>
        )
      }
    } else {
      return <></>
    }
  }
)

const SearchBar = () => {
  const inputRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      inputRef.current && inputRef.current.focus()
    }, 100)
  }, [])

  return (
    <div className="search-autocomplete-wrapper">
      <InstantSearch searchClient={searchClient} indexName="coucy_content">
        <CustomAutocomplete inputRef={inputRef} />
        <div className="search-autocomplete-result">
          <Results>
            {/*<Stats />*/}
            <CustomHits />
            {/*<RefinementList attribute="content_type" />*/}
          </Results>
        </div>
      </InstantSearch>
    </div>
  )
}

export default SearchBar
