import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { iconInvisibleText } from "../../Utils/functions"
import MenuLink from "../MenuLink"

const FooterMenu = () => {
  const data = useStaticQuery(graphql`
    {
      footerMenu: allMenuLinkContentMenuLinkContent(
        filter: { enabled: { eq: true }, menu_name: { eq: "footer" } }
        sort: { fields: weight, order: ASC }
      ) {
        nodes {
          title
          link {
            uri
            options {
              attributes {
                data_has_icon
                data_icon
                data_text_invisible
                target
              }
            }
          }
        }
      }
    }
  `)

  return (
    <ul
      id="footer--menu"
      className="flex flex-col md:flex-row md:justify-center md:items-center mt-2 md:mt-0 "
    >
      {data.footerMenu.nodes.map((menu, index) => {
        const attributes = menu.link.options
          ? menu.link.options.attributes
          : null

        return (
          <li
            className={`menu-item md:px-4 my-2 ${
              index > 0 ? `md:border-l border-l-primary` : ``
            }`}
            key={index}
          >
            <MenuLink
              internalId={menu.link.uri}
              className="menu-link font-semibold hover:text-secondary"
              {...attributes}
            >
              {attributes && attributes.data_has_icon ? (
                <>
                  <span className="icon">
                    <i className={attributes.data_icon}></i>
                  </span>
                  {attributes.data_text_invisible
                    ? iconInvisibleText(menu.title)
                    : menu.title}
                </>
              ) : (
                <>{menu.title}</>
              )}
            </MenuLink>
          </li>
        )
      })}
    </ul>
  )
}

export default FooterMenu
