import React, { useEffect, useState } from "react"
import Bus from "../Utils/Bus"

export const Flash = () => {
  let [visibility, setVisibility] = useState(false)
  let [message, setMessage] = useState("")
  let [type, setType] = useState("")

  useEffect(() => {
    Bus.addListener("flash", ({ message, type }) => {
      setVisibility(true)
      setMessage(message)
      setType(type)

      setTimeout(() => {
        setVisibility(false)
      }, 6000)
    })
  }, [])

  useEffect(() => {
    if (document.querySelector(".delete") !== null) {
      document
        .querySelector(".delete")
        .addEventListener("click", () => setVisibility(false))
    }
  })

  return (
    visibility && (
      <div className="notification-wrapper">
        <div className="container mx-auto px-4 lg:max-w-screen-lg">
          <div className={`notification notification-${type}`}>
            <div className="delete cursor-pointer">
              <i className="far fa-times-circle">
                <span className="hidden">Fermer</span>
              </i>
            </div>
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </div>
        </div>
      </div>
    )
  )
}
