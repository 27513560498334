import React from "react"
import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"
import HtmlParser from "../HtmlParser"

const InfosBlock = () => {
  const data = useStaticQuery(graphql`
    {
      block: blockContentBasic(drupal_internal__id: { eq: 1 }) {
        info
        body {
          value
        }
        links: field_links {
          title
          uri
        }
      }
    }
  `)

  return (
    <>
      <h2 className={`inline-block mb-4 text-center leading-none`}>
        <span className="block uppercase font-important">COUCY</span>
        <span className="font-handwritten text-2xl">Vert l'avenir</span>
      </h2>

      <div className="content mb-4">
        <HtmlParser html={data.block.body.value} />
      </div>

      {data.block.links && (
        <div className="links">
          {data.block.links.map(({ title, uri }, index) => {
            let type = uri.split(":")[0]
            let FacebookRegExp = new RegExp(/^https?:\/\/(www\.)?facebook./)

            return type === "entity" ? (
              <Link
                key={index}
                to={uri}
                className="block mb-2 font-semibold hover:text-secondary"
              >
                <span className="fas fa-external-link-square-alt mr-2" />
                <span>{title}</span>
              </Link>
            ) : (
              <a
                key={index}
                href={uri}
                className="block mb-2 font-semibold hover:text-secondary"
              >
                <span className="icon mr-2 text-secondary">
                  {type === "tel" ? (
                    <span className="fas fa-phone-square" />
                  ) : type === "mailto" ? (
                    <span className="fas fa-envelope" />
                  ) : FacebookRegExp.test(uri) ? (
                    <span className="fab fa-facebook-square" />
                  ) : (
                    <span className="fas fa-external-link-square-alt" />
                  )}
                </span>
                <span>{title}</span>
              </a>
            )
          })}
        </div>
      )}
    </>
  )
}

InfosBlock.propTypes = {
  title: PropTypes.string,
  titleDisplay: PropTypes.bool,
  titleSize: PropTypes.number,
}

InfosBlock.defaultProps = {
  title: "",
  titleDisplay: true,
  titleSize: 3,
}

export default InfosBlock
