import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ModalContext } from "../../contexts/ModalContext"
import { ModalSearch } from "../Modal/ModalSearch"
import MenuLink from "../MenuLink"

const QuickAccessMenu = ({ visibility, visibilityHandler }) => {
  const data = useStaticQuery(graphql`
    {
      quickAccessMenu: allMenuLinkContentMenuLinkContent(
        filter: { enabled: { eq: true }, menu_name: { eq: "quick-access" } }
        sort: { fields: weight, order: ASC }
      ) {
        nodes {
          drupal_parent_menu_item
          drupal_id
          title
          link {
            uri
            options {
              attributes {
                class
                target
                data_has_icon
                data_icon
                data_text_invisible
              }
            }
          }
        }
      }
    }
  `)

  const { setModalVisibility, setModalContent } = useContext(ModalContext)

  const barsClickHandler = () => {
    visibilityHandler(!visibility)
  }

  const barsKeyDownHandler = event => {
    if (event.key === "Enter") {
      barsClickHandler()
    }
  }

  const menuClickHandler = (event, link) => {
    let pathInfo = link.split(":")

    if (pathInfo[1] === "#search") {
      event.preventDefault()

      setModalContent(<ModalSearch />)

      setModalVisibility(true)
    }
  }

  return (
    <>
      <div className="flex items-center">
        <ul id="quick-access--menu" className="flex-1 inline-flex items-center">
          {data.quickAccessMenu.nodes.map((menu, index) => {
            const attributes = menu.link.options
              ? menu.link.options.attributes
              : null

            return (
              <li className="menu-item relative p-2" key={index}>
                <MenuLink
                  internalId={menu.link.uri}
                  className="menu-link group flex flex-nowrap items-center justify-center text-white hover:text-white lg:hover:text-primary text-2xl"
                  clickHandler={event => menuClickHandler(event, menu.link.uri)}
                >
                  {attributes && attributes.data_has_icon ? (
                    <>
                      <div
                        className={`icon${
                          !attributes.data_text_invisible ? ` mr-2` : ``
                        }`}
                      >
                        <i className={attributes.data_icon}>
                          <span className="hidden">{menu.title}</span>
                        </i>
                      </div>
                      <div className="opacity-0 lg:group-hover:opacity-100 absolute top-full left-1/2 transform -translate-x-1/2 transition-all px-2 bg-primary text-sm text-white uppercase whitespace-nowrap">
                        {menu.title}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="icon mr-2">
                        <i className="fas fa-link">
                          <span className="hidden">{menu.title}</span>
                        </i>
                      </div>
                      <div className="whitespace-nowrap">{menu.title}</div>
                    </>
                  )}
                </MenuLink>
              </li>
            )
          })}
        </ul>
        <div className="block lg:hidden">
          <button
            aria-label="menu"
            onClick={barsClickHandler}
            onKeyDown={barsKeyDownHandler}
            className="text-white text-2xl"
          >
            <div className="icon">
              <span className={`fas fa-${visibility ? `times` : `bars`}`}>
                <span className="hidden">Menu</span>
              </span>
            </div>
          </button>
        </div>
      </div>
    </>
  )
}

export default QuickAccessMenu
