import React from "react"
import PropTypes from "prop-types"
import {graphql, Link, useStaticQuery} from 'gatsby';
import HtmlParser from '../HtmlParser';

const OpeningHoursBlock = ({ title }) => {
  const data = useStaticQuery(graphql`
    {
      block: blockContentBasic(drupal_internal__id: { eq: 3 }) {
        info
        body {
          value
        }
        links: field_links {
          title
          uri
        }
      }
    }
  `)

  return (
    <>
      <h2
        className={`text-xl font-semibold font-secondary text-secondary mb-4`}
      >
        <span>{title ? title : data.block.info}</span>
      </h2>

      <div className="content mb-4">
        <HtmlParser html={data.block.body.value} />
      </div>

      {data.block.links && (
        <div className="links">
          {data.block.links.map(({ title, uri }, index) => {
            let type = uri.split(":")[0]
            let FacebookRegExp = new RegExp(/^https?:\/\/(www\.)?facebook./)

            return type === "entity" ? (
              <Link
                key={index}
                to={uri}
                className="block mb-2 font-semibold hover:text-secondary"
              >
                <span className="fas fa-external-link-square-alt mr-2" />
                <span>{title}</span>
              </Link>
            ) : (
              <a
                key={index}
                href={uri}
                className="block mb-2 font-semibold hover:text-secondary"
              >
                <span className="icon mr-2 text-secondary">
                  {type === "tel" ? (
                    <span className="fas fa-phone-square" />
                  ) : type === "mailto" ? (
                    <span className="fas fa-envelope" />
                  ) : FacebookRegExp.test(uri) ? (
                    <span className="fab fa-facebook-square" />
                  ) : (
                    <span className="fas fa-external-link-square-alt" />
                  )}
                </span>
                <span>{title}</span>
              </a>
            )
          })}
        </div>
      )}
    </>
  )
}

OpeningHoursBlock.propTypes = {
  title: PropTypes.string,
  titleDisplay: PropTypes.bool,
  titleSize: PropTypes.number,
}

OpeningHoursBlock.defaultProps = {
  title: "",
  titleDisplay: true,
  titleSize: 3,
}

export default OpeningHoursBlock
